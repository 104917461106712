import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';

export const pageQuery = graphql`
  query NewsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
      }
    }
  }
`;

export default ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <div className="container sub-content" style={{ minHeight: 600 }}>
      <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
      <div style={{ marginBottom: 10 }}>
        <Link to="/news">一覧へ</Link>
      </div>
      <h1 style={{ fontSize: 27 }}>{post.frontmatter.title}</h1>
      <p style={{ fontStyle: 'italic' }}>{post.frontmatter.date}</p>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <div style={{ marginTop: 50 }}>
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          {previous && (
            <li>
              <Link to={previous.fields.slug} rel="prev">
                &lt; {previous.frontmatter.title}
              </Link>
            </li>
          )}

          {next && (
            <li>
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} &gt;
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
